import Vue from "vue";
import VueRouter from "vue-router";
import teamRoutes from "../router/team";
import chartRoutes from "../router/fchart";
import lightRoutes from "../router/lchart";
import userRoutes from "@/router/user";
import noodplanRoutes from "@/router/noodplan";
Vue.use(VueRouter);
import middlewarePipeline from "@/router/middlewarePipeline";
import store from "@/store/index";

let allRoutes = [];
allRoutes = allRoutes.concat(
  teamRoutes,
  chartRoutes,
  userRoutes,
  lightRoutes,
  noodplanRoutes
);

const routes = allRoutes;

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const middleware = to.meta.middleware;
  const context = { to, from, next, store };

  if (!middleware) {
    return next();
  }

  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
