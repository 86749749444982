import Vue from "vue";
import Vuex from "vuex";
import * as auth from "@/store/modules/Auth";
import * as user from "@/store/modules/User/User";
import * as message from "@/store/modules/Message";
import * as usersingle from "@/store/modules/User/UserSingle";
import * as team from "@/store/modules/Team/Team";
import * as teamsingle from "@/store/modules/Team/TeamSingle";
import * as amain from "@/store/modules/Fsmain/Amain";
import * as bmain from "@/store/modules/Fsmain/Bmain";
import * as cmain from "@/store/modules/Fsmain/Cmain";
import * as dmain from "@/store/modules/Fsmain/Dmain";
import * as emain from "@/store/modules/Fsmain/Emain";
import * as fmain from "@/store/modules/Fsmain/Fmain";
import * as gmain from "@/store/modules/Fsmain/Gmain";
import * as hmain from "@/store/modules/Fsmain/Hmain";
import * as imain from "@/store/modules/Fsmain/Imain";
import * as jmain from "@/store/modules/Fsmain/Jmain";
import * as kmain from "@/store/modules/Fsmain/Kmain";
import * as lmain from "@/store/modules/Fsmain/Lmain";
import * as mmain from "@/store/modules/Fsmain/Mmain";
import * as nmain from "@/store/modules/Fsmain/Nmain";
import * as omain from "@/store/modules/Fsmain/Omain";
import * as fchart from "@/store/modules/Fchart/Fchart";
import * as colors from "@/store/modules/Maintenance/Color";
import * as fchartresult from "@/store/modules/Fchart/Fchartresult";
import * as fchartBresult from "@/store/modules/Fchart/FchartBresult";
import * as fchartCresult from "@/store/modules/Fchart/FchartCresult";
import * as fchartDresult from "@/store/modules/Fchart/FchartDresult";
import * as fchartEresult from "@/store/modules/Fchart/FchartEresult";
import * as fchartFresult from "@/store/modules/Fchart/FchartFresult";
import * as fchartGresult from "@/store/modules/Fchart/FchartGresult";
import * as fchartHresult from "@/store/modules/Fchart/FchartHresult";
import * as fchartIresult from "@/store/modules/Fchart/FchartIresult";
import * as fchartJresult from "@/store/modules/Fchart/FchartJresult";
import * as fchartKresult from "@/store/modules/Fchart/FchartKresult";
import * as fchartLresult from "@/store/modules/Fchart/FchartLresult";
import * as fchartMresult from "@/store/modules/Fchart/FchartMresult";
import * as fchartNresult from "@/store/modules/Fchart/FchartNresult";
import * as fchartOresult from "@/store/modules/Fchart/FchartOresult";
import * as lchartAresult from "@/store/modules/Lchart/LchartAresult";
import * as lchartBresult from "@/store/modules/Lchart/LchartBresult";
import * as lchartCresult from "@/store/modules/Lchart/LchartCresult";
import * as lchartDresult from "@/store/modules/Lchart/LchartDresult";
import * as lchartEresult from "@/store/modules/Lchart/LchartEresult";
import * as lchartFresult from "@/store/modules/Lchart/LchartFresult";
import * as lchart from "@/store/modules/Lchart/Lchart";
import * as phSingle from "@/store/modules/nplan/NplanPhSingle";
import * as bvSingle from "@/store/modules/nplan/NplanBvSingle";
import * as bv from "@/store/modules/nplan/NplanBv";
import * as ph from "@/store/modules/nplan/NplanPh";
Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    auth,
    user,
    message,
    usersingle,
    team,
    teamsingle,
    amain,
    bmain,
    cmain,
    dmain,
    emain,
    fmain,
    gmain,
    hmain,
    imain,
    jmain,
    kmain,
    lmain,
    mmain,
    nmain,
    omain,
    fchart,
    fchartresult,
    fchartBresult,
    fchartCresult,
    fchartDresult,
    fchartEresult,
    fchartFresult,
    fchartGresult,
    fchartHresult,
    fchartIresult,
    fchartJresult,
    fchartKresult,
    fchartLresult,
    fchartMresult,
    fchartNresult,
    fchartOresult,
    lchart,
    lchartAresult,
    lchartBresult,
    lchartCresult,
    lchartDresult,
    lchartEresult,
    lchartFresult,
    bvSingle,
    phSingle,
    bv,
    ph,

    colors,
  },
});
